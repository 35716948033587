<template>
  <div>
    <h1>Universal Analytics Archives - Monthly Counts</h1>
    <v-card class="my-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="2">
          <v-select
            outlined
            v-model="table"
            :items="tables"
            :item-text="(table) => table.text"
            item-value="value"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Choose table:"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            outlined
            v-model="selectedSites"
            :items="sites"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Sélectionner des sites"
            item-text="name"
            item-value="id"
            multiple
            small-chips
            deletable-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            v-model="frequency"
            :items="frequencies"
            :item-text="(item) => item.text"
            item-value="id"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Choose Frequency:"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-btn
            @click="fetchSiteViews"
            class="pt-7 pb-6 mt-0 my-1"
            color="primary"
          >
            Valider
          </v-btn>
        </v-col>
        <v-col cols="2"> </v-col>
        <v-col cols="2">
          <!-- <v-btn
            @click="resetFilters"
            class="pt-7 pb-6 mt-0 my-1"
            outlined
            color="secondary"
          >
            Réinitialiser
          </v-btn> -->
        </v-col>
      </v-row>
      <v-row class="mx-3" v-for="siteView in siteViews" :key="siteView.id">
        <v-col>
          <data-timespan-counts-site-view-chart
            :table="selectedTable"
            :frequency="selectedFrequency"
            :siteView="siteView"
            :componentKey="dataMonthCountsSiteViewChartComponentKey"
          ></data-timespan-counts-site-view-chart>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import DataTimespanCountsSiteViewChart from "@/components/audience/universal-analytics/charts/DataTimespanCountsSiteViewChart";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "UADataMonthCounts",
  components: {
    DataTimespanCountsSiteViewChart,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "fullWidth");
    // this.fetchTables();
    this.fetchSites();
    this.selectedSites = this.defaultSelectedSites;
    this.fetchSiteViews();
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
  computed: {
    // sites() {
    //   return this.$store.getters["general/getSites"];
    // },
  },
  methods: {
    setSelectSites(sites) {
      this.selectedSites = sites;
    },
    resetFilters() {
      this.selectedSites = this.defaultSelectedSites;
    },
    async fetchSiteViews() {
      try {
        const params = {
          table: this.selectedTable,
          sites: this.selectedSites.join(","),
        };
        const response = await axios.get(
          "/universal-analytics/site-views-list",
          {
            params: params,
          }
        );
        this.siteViews = response.data;
        this.selectedTable = this.table;
        this.selectedFrequency = this.frequency;
        this.dataMonthCountsSiteViewChartComponentKey += 1;
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    },
    // async fetchTables() {
    //   try {
    //     const response = await axios.get(
    //       "/universal-analytics/tables-with-dimensions"
    //     );
    //     this.tablesWithDimensions = response.data;
    //     // this.tables = Object.keys(this.tablesWithDimensions);
    //     this.tables = Object.keys(this.tablesWithDimensions).map((table) => ({
    //       text: table.replace(/^ua__/, ""), // .charAt(0).toUpperCase() + table.replace(/^ua__/, "").slice(1),
    //       value: table,
    //     }));
    //     console.log("tables", this.tables);
    //   } catch (error) {
    //     console.error("Error fetching tables:", error);
    //   }
    // },
    async fetchSites() {
      try {
        const response = await axios.get("/universal-analytics/sites");
        this.sites = response.data;
      } catch (error) {
        console.error("Error fetching sites:", error);
      }
    },
  },
  data() {
    return {
      frequency: 5,
      frequencies: [
        { id: 5, text: "Monthly" },
        { id: 3, text: "Weekly (is only for Supersoluce)" },
      ],
      selectedFrequency: 5,
      sites: [],
      selectedSites: [],
      defaultSelectedSites: [2],
      table: "ua__site",
      tables: [],
      tablesWithDimensions: {
        ua__site: [],
        ua__path_recipe: ["path"],
        ua__landing_page: ["landing_page"],
        ua__section_subsection_type_path_clics_affiliation: [
          "section",
          "subsection",
          "content_type",
          "path",
        ],
        ua__path_category: ["path", "category"],
        ua__section_subsection_type: ["section", "subsection", "content_type"],
        ua__source: ["source"],
        ua__section_subsection_type_path: [
          "section",
          "subsection",
          "content_type",
          "path",
        ],
        ua__country_city: ["country", "city"],
        ua__section_subsection_type_plays_video: [
          "section",
          "subsection",
          "content_type",
        ],
        ua__user_status: ["user_status"],
        ua__channel_group: ["channel_group"],
        ua__campaign: ["campaign"],
        ua__path_recipe_diets_ingredients: [
          "path",
          "diets",
          "ingredients",
          "ingredients_ids",
        ],
        ua__section_subsection_type_clics_affiliation: [
          "section",
          "subsection",
          "content_type",
        ],
        ua__country: ["country"],
        ua__medium: ["medium"],
        ua__device: ["device"],
        ua__section_subsection_type_path_plays_video: [
          "section",
          "subsection",
          "content_type",
          "path",
        ],
        ua__ops: ["ops"],
        ua__levels3456: ["level_3", "level_4", "level_5", "level_6"],
        ua__path_level12: ["path", "level_1", "level_2"],
        ua__path_section: ["path", "section"],
        ua__path: ["path"],
        ua__source_medium: ["source", "medium", "source_medium"],
        ua__author: ["author"],
        ua__age: ["age"],
        ua__gender: ["gender"],
      },
      selectedTable: "ua__site",
      selectedSite: null,
      siteViews: [],
      dataMonthCountsSiteViewChartComponentKey: 1,
      tables: [
        {
          text: "Pack commun: site",
          value: "ua__site",
        },
        {
          text: "Pack commun: age",
          value: "ua__age",
        },
        {
          text: "Pack commun: gender",
          value: "ua__gender",
        },
        {
          text: "Pack commun: device",
          value: "ua__device",
        },
        {
          text: "Pack commun: path",
          value: "ua__path",
        },
        {
          text: "Pack commun: campaign",
          value: "ua__campaign",
        },
        {
          text: "Pack commun: country",
          value: "ua__country",
        },
        {
          text: "Pack commun: country_city",
          value: "ua__country_city",
        },
        {
          text: "Pack commun: source",
          value: "ua__source",
        },
        {
          text: "Pack commun: medium",
          value: "ua__medium",
        },
        {
          text: "Pack commun: source_medium",
          value: "ua__source_medium",
        },
        {
          text: "Pack commun: channel_group",
          value: "ua__channel_group",
        },
        {
          text: "Les numériques, CNet et ZDnet: section_subsection_type",
          value: "ua__section_subsection_type",
        },
        {
          text: "Les numériques, CNet et ZDnet: section_subsection_type_path",
          value: "ua__section_subsection_type_path",
        },
        {
          text: "Les numériques et CNet: section_subsection_type_clics_affiliation",
          value: "ua__section_subsection_type_clics_affiliation",
        },
        {
          text: "Les numériques: section_subsection_type_path_clics_affiliation",
          value: "ua__section_subsection_type_path_clics_affiliation",
        },
        {
          text: "Les numériques: section_subsection_type_plays_video",
          value: "ua__section_subsection_type_plays_video",
        },
        {
          text: "Les numériques: section_subsection_type_path_plays_video",
          value: "ua__section_subsection_type_path_plays_video",
        },
        {
          text: "Docti: landing_page",
          value: "ua__landing_page",
        },
        {
          text: "Docti et Netmums: author",
          value: "ua__author",
        },
        {
          text: "Netmums: path_section",
          value: "ua__path_section",
        },
        {
          text: "Marmiton: ops",
          value: "ua__ops",
        },
        {
          text: "Marmiton: levels3456",
          value: "ua__levels3456",
        },
        {
          text: "Marmiton: path_recipe",
          value: "ua__path_recipe",
        },
        {
          text: "Marmiton: path_recipe_diets_ingredients",
          value: "ua__path_recipe_diets_ingredients",
        },
        {
          text: "Science & Vie: user_status",
          value: "ua__user_status",
        },
        {
          text: "Grazia.fr: path_level12",
          value: "ua__path_level12",
        },
        {
          text: "Grazia a l'étranger: path_category",
          value: "ua__path_category",
        },
      ],
    };
  },
};
</script>
